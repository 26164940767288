<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'ABOUT US PROFILE'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.aboutUsProfileAddModal.show()"
                     text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disabled="{rowData}">
                <checkbox-input v-model="rowData.disabled" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #image="{rowData}">
                <div class="bg-1">
                    <img :src="rowData.image" width="100" height="100" alt="Avatar"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add About Us Profile" ref="aboutUsProfileAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddAboutUsProfile @success="formSuccess"></AddAboutUsProfile>
        </modal>

        <modal title="Edit About Us Profile" ref="aboutUsProfileEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditAboutUsProfile :initial-data="editingItem" @success="formSuccess"></EditAboutUsProfile>
        </modal>

        <delete-modal ref="aboutUsProfileDeleteModal" :url="deleteUrl" :params="deletingItem"
                      @response="deleteComplete">
            <p>You are about to delete the About Us Profile <b v-html="deletingItem && deletingItem.about_us"></b>. Are
                you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';
import AddAboutUsProfile from './AddAboutUsProfile';
import EditAboutUsProfile from './EditAboutUsProfile';

export default {
    name       : 'AboutUsProfile',
    components : { EditAboutUsProfile, AddAboutUsProfile },
    computed   : { ...mapGetters(['currentUser']) },
    data () {
        return {
            prefix       : '',
            listUrl      : urls.admin.event.aboutUsProfile.list,
            deleteUrl    : urls.admin.event.aboutUsProfile.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'heading',
                    sortField : 'heading',
                    title     : 'Heading'
                },
                {
                    name      : '__slot:image',
                    sortField : 'image',
                    title     : 'Image'
                },
                {
                    name      : 'para_1',
                    sortField : 'para_1',
                    title     : 'Paragraph 1'
                },
                {
                    name      : 'para_2',
                    sortField : 'para_2',
                    title     : 'Paragraph 2'
                },
                {
                    name       : '__slot:disabled',
                    sortField  : 'disabled',
                    title      : 'Disabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.aboutUsProfileAddModal.close();
            refs.aboutUsProfileEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },
        viewFile (file) {
            window.open(file);
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.aboutUsProfileEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.aboutUsProfileDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted About Us Profile..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.aboutUsProfileDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            const key = {
                api_key : that.currentUser.api_key
            };
            const data = {
                ...key,
                ...item
            };
            axios.form(urls.admin.event.aboutUsProfile.addEdit, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated About Us Profile..!', 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disabled = !item.disabled;
                }
            }).catch(function (err) {
                item.disabled = !item.disabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
