<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-input class="col-12" label="Heading" name="Heading" v-model="model.heading"
                             :rules="{required:true}" :disabled="loading"/>
        </div>

        <div class="row">
            <file-input class="c-file-input col-12" label="Image" v-model="model.image" :disabled="loading"/>
        </div>
        <div class="row">
            <div class="col">
                <image-info :width="380" :height="380"/>
            </div>
        </div>

        <div class="row">
            <validated-textarea class="col-12" label="Para 1" name="Para 1"
                                v-model="model.para_1" :rules="{required:true, min:540, max:560}" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-textarea class="col-12" label="Para 2" name="Para 2" v-model="model.para_2"
                                :rules="{required:true, min:540, max:560}" :disabled="loading"/>
        </div>

        <div class="row">
            <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                            :rules="{required:false}" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddAboutUsProfile',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.aboutUsProfile.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added About Us Profile...!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
